import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import React , { useContext }from "react"
import styled from 'styled-components'
import Img from "gatsby-image"
import { uuid as key}  from 'uuidv4';
import {slug} from '../../utils/utils.js'

const Main = styled.main`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 0;
    gap: 2rem;
    img {
        margin-bottom: 0;
        width: 100%;
    }
    a:hover {
        opacity: 0.5 !important;

    }
    .banner {
        position: relative;
        min-height:500px;
        width:100%;
        overflow: hidden;
    }
    video {
        position: absolute;
        top: 0;
        min-width: 100%;
        min-height: 100%;
        height:auto;
        z-index: -100;
        object-fit: cover;
    }
    @media screen and (max-width: 1200px) {
        margin-top: 2rem;
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media screen and (max-width: 920px) {
        margin-top: 2rem;
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 768px) {
        margin-top: 0;
        grid-template-columns: 1fr;

        > div {
            margin: 0 ;
        }
    }
`;

const HeaderTwo = styled.h2`
    margin: 1rem 0 1.5rem 0;
    display: block;
    text-align: left;
    color: black;
    font-weight: 500;
    color: black;
    font-size: 1.5rem;
    &:hover {
        opacity: 0.5 ;
    }
    @media screen and (max-width: 768px) {
        font-size: 1.2rem;
        margin: .7rem 0 0 0;
    }
`;

interface Props {
    pageContext: {
        slug: string
    },
    data: {
        allContentfulCategory: {
            nodes: Array<any>
        }
    },
    location: Object
}


interface Post {
    title: String,
    tags: Array<String>
    mainImage: {
        fluid: Object
    }
}
const Category = ({ data }: Props) => {
    if (!data.allContentfulCategory.nodes[0]) return <div>cannot find category</div>;
    const posts = data.allContentfulCategory.nodes[0].posts;
    return (
        <div>
            <Main key={key()}>
                {posts.map((post: Post, i: Number) => {
                    const { title, mainImage, tags, videoThumbnail } = post;
                    
                    const fluid = mainImage ? mainImage.fluid : null;
                    if (title && mainImage) {
                        return <div key={key()}>
                            <Link to={`/project/${slug(title.toLowerCase())}`}>
                                {mainImage.file.contentType.includes("video") ?
                                    <div className="banner">
                                          <video poster={videoThumbnail ? `${videoThumbnail.file.url}?h=400` :'http://www.musecology.com/images/light-gray-bg.png'} width="320" height="240" muted autoPlay playsInline>
                                            <source src={`${mainImage.file.url}#t=0`} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                :
                                    <Img 
                                        fluid={mainImage.fluid} 
                                        backgroundColor="true"
                                    />
                                }
                                <HeaderTwo>{title}</HeaderTwo>
                            </Link>
                        </div>
                    }
                })}
            </Main>
        </div>
    )
}

export const query = graphql`
    query PostQuery($query: String!) {
        allContentfulCategory(filter: {title: {eq: $query }}) {
            nodes {
              posts {
                title
                    tags
                    mainImage {
                        file {
                            url
                            contentType
                        }
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                    videoThumbnail {
                        file{
                            url
                            contentType
                        }
                    }
              }
            }
        }
    }
`

Category.defaultProps = {
    title: ''
}


export default Category
