export const slug = (str) => {
    return str.trim().split(' ').join('-').toLowerCase();
}


const debounce = (func, wait, immediate) => {
    let timeout
    return function () {
        let context = this,
            args = arguments
        let later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        let callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

export default debounce

